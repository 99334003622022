package app

class SingleDisplay(
    val spotifyLink: String,
    val imageSrc: String,
    val altText: String,
    val releaseOrder: Int,
    val fileType: String = "png"
)

val ATTITUDE = SingleDisplay(
    spotifyLink = "https://open.spotify.com/embed/album/7HmOZmJDME40l9BPYEavBl",
    imageSrc = "attitude/attitude",
    altText = """
            Attitude Single Art; a lambo driving towards the viewer with a
            city scape behind. The city is cyberpunk themed and has lots of 
            neon lights. The color scheme is purple and blue and glowing. The
            overall look is painted roughly. It has the word 'Lazerblade' in the middle
            with the words 'Attitude' below it. There are three next to each other
            and the font make it look like a heartbeat monitor.
        """.trimIndent(),
    releaseOrder = 1
)

val DARK_ELF = SingleDisplay(
    spotifyLink = "https://open.spotify.com/embed/album/2wtVrkfRPHcM0YXDRc6zvC",
    imageSrc = "dark-elf/dark-elf-art",
    altText = """
            Dark Elf Single Art; A picture of Zero's face from Megaman. 
            Red with a blue diamond on his forehead. Except this is flipped
            upside down and is edited to look like it is made up of squares.
            
            The middle has the word 'Dark Elf' sunken into the picture, each word 
            on its own line, and stylized as yellow blocks. The word lazerblade in between, raised up.
        """.trimIndent(),
    releaseOrder = 2
)

val NES = SingleDisplay(
    spotifyLink = "https://open.spotify.com/embed/album/3mAAaCQ5IoPKfaIiTerDYW",
    imageSrc = "nes/nes-art",
    altText = """
            NES Single Art; A close up picture of a black snake coils. It is very close up and has
            a wet pink purple color to it. On top of the picutre there is a stylize NES word with
            the work LAZERBLADE below sunken in as well. The test is teal.
        """.trimIndent(),
    releaseOrder = 3
)

val SONIC_SEA = SingleDisplay(
    spotifyLink = "https://open.spotify.com/embed/track/3t1HNIyI96d01mmRkSDv6e?utm_source=generator",
    imageSrc = "sonic-sea/sonic-sea-art",
    altText = """
            Sonic Sea Single Art; A picture of a sail boat heading at an angle towards the front left of
            the viewer. There are waves crashing to the right. Behind the sail is a bright teal sun.
            The sky and waves are a mixture of pink and teal. The graphic black words say The Sonic Sea in the
            top left corner. The wors also have a purple outline. The word "Lazerblade" is at the bottom.
        """.trimIndent(),
    releaseOrder = 4,
    fileType = "jpg"
)

val SUPER_8 = SingleDisplay(
    spotifyLink = "https://open.spotify.com/embed/album/0QsQ4y81W9jurKhvcUWEbf?utm_source=generator&theme=0",
    imageSrc = "super-8/super_8_art",
    altText = """
            The image depicts a stylized representation of the Grim Reaper standing with open arms. 
            The scene is set against a vibrant backdrop that contrasts a bright yellow sun with a dark, 
            almost nocturnal foreground. The figure of Death is central, wearing a traditional hooded cloak, 
            and a skull is visible within the hood, suggesting a skeletal face. The background includes 
            detailed depictions of gravestones and a small chapel or mausoleum, framed by trees and underbrush. 
            The style of the image is reminiscent of vector art, with clean lines and bold,
            contrasting colors creating a graphic, almost poster-like effect. Birds are shown in silhouette, 
            flying across the scene, adding to the eerie yet vibrant ambiance.
        """.trimIndent(),
    releaseOrder = 5,
)

val SINGLES = sequenceOf(DARK_ELF, ATTITUDE, NES, SONIC_SEA, SUPER_8).sortedByDescending(SingleDisplay::releaseOrder)