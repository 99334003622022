package app

import dev.fritz2.core.*

fun main() {

    render {
        header(id = "banner") { }

        div("divider flex justify-center") {
            a("border rounded-lg px-5 py-4 border-pink-400 text-pink-400 my-2") {
                +"Instagram ∴ @lazerblade_____________________"
                target("_blank")
                href("https://instagram.com/lazerblade____________________?igshid=OGQ5ZDc2ODk2ZA==")
            }
        }

//        labelDivider("Live Shows")
//
//        card("flex justify-center py-10") {
//            img("max-w-full h-[500px] sm:h-auto") {
//                src("assets/live-shows/2023-06-28-MOTC-Landmark.webp")
//                alt("Landmark Center Show June 28th, 2023 at noon until 1pm")
//            }
//        }

        labelDivider("Singles")

        // card
        card(
            otherClasses = "flex flex-col items-center 2xl:flex-row 2xl:flex-wrap 2xl:justify-between pt-6 md:pt-12 md:px-12 md:mb-[100px] w-[100%] h-[100%]",
            id = "singles-list"
        ) {
            SINGLES.forEach(::singleDisplay)
        }

        labelDivider("Singles Videos")

        card("flex flex-col items-center lg:justify-between lg:flex-row lg:flex-wrap  pt-16 px-16 mb-[100px] w-[100%] h-[100%]") {
            sequenceOf(
                "https://www.youtube.com/embed/hWxMXdwnAzM",
                "https://www.youtube.com/embed/q_doMQvBU-w",
                "https://www.youtube.com/embed/vWk1SxyF0II",
                "https://www.youtube.com/embed/uNmMAV-FFlI",
                "https://www.youtube.com/embed/v6Tamj2uqOM"
            ).forEach(::youtubeIFrame)
        }
    }
}

fun RenderContext.labelDivider(name: String) =
    section("dark-font mx-auto my-10 w-[80%] text-[28px] md:text-[89px] p22-arts-and-crafts") { +name }

fun RenderContext.card(otherClasses: String = "", id: String = "", render: RenderContext.() -> Unit) = div("flex justify-center") {
    main("min-h-[800px] md:min-h-[1200px] border border-pink-400 rounded-lg card shadow-md $otherClasses", id = id) {
        render(this)
    }
}

fun RenderContext.singleDisplay(singleDisplay: SingleDisplay) = div("flex flex-col items-center mb-10") {
    iframe("bottom-[10px]") {
        src(singleDisplay.spotifyLink)
        height("100")
        attr("allowtransparency", "true")
        attr("allow", "encrypted-media")
    }

    img("m-[30px] w-[200px] md:w-[400px] lg:w-[500px]") {
        src("assets/singles/${singleDisplay.imageSrc}-500x500.${singleDisplay.fileType}")
        alt(singleDisplay.altText)
    }
}

fun RenderContext.youtubeIFrame(src: String) {
    iframe("bottom-[10px] youtube-iframe") {
        src(src)
        height("315")
        attr("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture")
        allowFullscreen(true)
    }
}

// header can't handle the raditude